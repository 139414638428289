import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

type TimerProps = {
  isRunning: boolean;
  variant?: any;
  currentTimer?: number;
  color?: string;
};

const Timer = ({
  isRunning,
  variant,
  currentTimer,
  color = 'black',
}: TimerProps) => {
  // state to store time
  const [time, setTime] = useState(currentTimer || 0);
  // state to check stopwatch running or not

  useEffect(() => {
    let intervalId: any;
    if (isRunning) {
      // setting time from 0 to 1 every 10 milisecond using javascript setInterval method
      intervalId = setInterval(() => setTime(time + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  const hours = Math.floor(time / 360000);
  const minutes = Math.floor((time % 360000) / 6000);
  const seconds = Math.floor((time % 6000) / 100);
  return (
    <Typography component="span" variant={variant} color={color}>
      {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </Typography>
  );
};

export default Timer;
