import { apiSlice } from '../api/apiSlice';

export const dailyAttendanceApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    editDailyAttendance: builder.mutation<any, any>({
      query: ({ dateUnix, id, payload }) => ({
        url: `/time-management/daily-attendance/${dateUnix}/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['listDailyAttendance'],
    }),
  }),
});

export const { useEditDailyAttendanceMutation } = dailyAttendanceApiSlice;
