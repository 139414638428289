import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import login from '../../../validations/login';
import {
  useSendOtpMutation,
  useValidateEmailMutation,
} from '../../../store/feature/service/authApiSlice';
import { getTld } from '../../../utils/cookies';
import { isDevelopment } from '../../../utils/env';
import { removeMessage } from '../../../store/feature/authSlice';
import LOCAL_STORAGE_KEY from '../../../constants/localStorage';
import { mappingError } from '../../../utils/errorMapping';
import LogoLogin from '../../atom/icon/LoginLogo';
import InputWithLabel from '../../molecule/form/InputWithLabel/InputWithLabel';
import { Button, Input } from '../../atom';
import Eye from '../../atom/icon/Eye';
import EyeOff from '../../atom/icon/EyeOff';
import Alert from '../../atom/alert/Alert';
import Key02 from '../../atom/icon/Key02';

type LoginWithEmailProps = {
  setIsLoginWithEmail: Dispatch<SetStateAction<boolean>>;
};

const LoginWithEmail = ({ setIsLoginWithEmail }: LoginWithEmailProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showInputPassword, setShowInputPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { messageError } = useAppSelector(state => state.auth);

  const { control, setError, watch, handleSubmit } = useForm<any>({
    resolver: yupResolver(login),
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [sendOtp, { isLoading: isLoadingSendOtp }] = useSendOtpMutation();
  const [validateEmail, { isLoading: isLoadingValidateEmail }] =
    useValidateEmailMutation();

  useEffect(() => {
    if (!watch('password')) {
      setShowPassword(false);
    }
  }, [watch('password')]);

  useEffect(() => {
    const domain = window.location.hostname;
    const tld = getTld(domain);
    if (domain !== tld) {
      if (isDevelopment()) {
        window.location.href = `${window.location.protocol}//${tld}:${window.location.port}`;
      } else {
        window.location.href = `${window.location.protocol}//${tld}`;
      }
    }
  }, [window.location.hostname]);

  const navigateToForgetPassword = () => {
    navigate('/forgot-password');
  };

  const submit = async (e: any) => {
    try {
      dispatch(removeMessage());
      await sendOtp(e).unwrap();
      const expired = Math.floor(
        new Date(Number(new Date()) + 1 * 60 * 1000).valueOf() / 1000
      );
      localStorage.setItem('otp__expiry_date', JSON.stringify(expired));
      localStorage.setItem(LOCAL_STORAGE_KEY.USER_EMAIL, e.email);
      setTimeout(() => {
        navigate('/otp');
      }, 500);
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError, 'login');
    }
  };

  const validateEmailLogin = async () => {
    try {
      await validateEmail({
        email: watch('email'),
      }).unwrap();
      setShowInputPassword(true);
    } catch (error) {
      const err = error as any;
      mappingError(err.data.errors, setError, 'login');
    }
  };

  const styles: SxProps = {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '80px',
    gap: '32px',
    '& .container-contact-us': {
      display: 'flex',
    },
    '& .button-to-click': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& .caption-container': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
    },
    '& .input-container': {
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      '& .button-submit': {
        width: '100%',
        marginTop: '4px',
      },
      '& .MuiFormControl-root': {
        '& .MuiButtonBase-root': {
          border: 'none',
          '&:hover': { background: 'none' },
        },
      },
      '& .input-form .MuiFormLabel-root': {
        marginBottom: '0.5rem',
      },
      '& .input-form .input--error .MuiInputBase-root fieldset': {
        borderColor: `${palette.red[50]} !important`,
        '&:focused': {
          borderColor: `${palette.red[50]} !important`,
        },
      },
    },
  };

  return (
    <Box sx={styles}>
      <LogoLogin />
      <Box className="caption-container">
        <Typography
          color={palette.gray[100]}
          variant="h5"
          fontWeight={700}
          component="h5"
        >
          Log In
        </Typography>
        <Typography color={palette.gray[80]} variant="body14" component="p">
          Welcome back! Please log in to access TricorUnity.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(submit)}>
        <Box className="input-container">
          <InputWithLabel
            label="Email"
            name="email"
            control={control}
            placeholder="Enter your email"
          />
          {showInputPassword && (
            <FormControl className="input-form control" fullWidth>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                }}
              >
                <FormLabel htmlFor="password">
                  <Typography
                    color={palette.gray[100]}
                    variant="body14"
                    fontWeight={500}
                  >
                    Password
                  </Typography>
                </FormLabel>
                <Typography
                  color={palette.blue[50]}
                  variant="body14"
                  component="p"
                  fontWeight={600}
                  className="button-to-click"
                  onClick={navigateToForgetPassword}
                >
                  Forgot Password
                </Typography>
              </Box>
              <Controller
                control={control}
                name="password"
                render={({ field, formState }) => {
                  const { errors } = formState as any;
                  const isError =
                    Object.keys(errors).length > 0 &&
                    Object.prototype.hasOwnProperty.call(errors, 'password');
                  return (
                    <>
                      <Input
                        id="password"
                        className={isError ? 'input--error' : ''}
                        placeholder="••••••••"
                        type={showPassword ? 'text' : 'password'}
                        {...field}
                        error={isError}
                        suffixIcon={
                          <Button
                            onClick={() => setShowPassword(!showPassword)}
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            variant="outlined"
                          >
                            {showPassword ? <Eye /> : <EyeOff />}
                          </Button>
                        }
                      />
                      {isError && (
                        <Typography
                          marginTop="8px"
                          component="p"
                          variant="body14"
                          color={palette.red[50]}
                        >
                          {t(errors?.password?.message)}
                        </Typography>
                      )}
                    </>
                  );
                }}
              />
            </FormControl>
          )}
          {messageError && <Alert variant="warning" message={messageError} />}
          {showInputPassword && (
            <Button
              disabled={
                !watch('password') || !watch('email') || isLoadingSendOtp
              }
              variant="primary"
              type="submit"
              className="button-submit"
            >
              Log In
            </Button>
          )}
          {!showInputPassword && (
            <Button
              onClick={validateEmailLogin}
              disabled={!watch('email') || isLoadingValidateEmail}
              variant="primary"
              type="button"
            >
              Continue
            </Button>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Divider sx={{ flex: 1 }} />
            <Box
              sx={{
                flex: 0.4,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography color={palette.gray[100]} variant="body14">
                or
              </Typography>
            </Box>
            <Divider sx={{ flex: 1 }} />
          </Box>
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setIsLoginWithEmail(false);
            }}
          >
            <Key02 /> Login In with SSO
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default LoginWithEmail;
